import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { ErrorMessage, FormLine, SubmitButton } from './form';
import { Formik } from 'formik';

import api from '../api/client';
import * as Yup from 'yup';

const schema = Yup.object().shape({
    pinyin: Yup.string().label("Pinyin").default(' '),
    chinese: Yup.string().label("Chinese").default(' '),
    english: Yup.string().label("English").default(' '),
    example: Yup.string().label("Example Sentence").default(' '),
    expEnglish: Yup.string().label("Example English").default(' ')
});

export default function IdiomForm({ route }) {

    const { ID } = route.params;
    const [cID, setCID] = useState(ID);

    const [error, setError] = useState(false);
    const [values, setValues] = useState({pinyin: '', chinese: '', english: '', example: '', expEnglish: ''});

    useEffect(() => {
        if (cID > 0)
            fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await api.get('/Vocab/Idiom/ID/' + cID);
        const { pinyin, chinese, english, example, expEnglish } = data;
        setValues({ pinyin, chinese, english, example, expEnglish });
    }

    const saveVocab = async (values) => {
        const { pinyin, chinese, english, example, expEnglish } = values;

        if (cID == 0)
        {
            const { data } = await api.get("/Vocab/Idiom/" + values.chinese);
            const { cid, english } = data;

            if (english != null)
                setValues(data);
            
            setCID(cid);
        } else {
            if (english == "")
                console.log('english null: ', english);

            let v = new FormData();
            v.append("CID", cID);
            v.append("Pinyin", pinyin.length == 0? " ": pinyin);
            v.append("Chinese", chinese.length == 0? " ": chinese);
            v.append("English", english.length == 0? " ": english);
            v.append("Example", example.length == 0? " ": example);
            v.append("ExpEnglish", expEnglish.length == 0? " ": expEnglish);
    
            const { data } = await api.post("/Vocab/Idiom/", v);
            console.log('insert result: ', data);

            if (data == 1) {
                setValues({pinyin: '', chinese: '', english: '', example: '', expEnglish: ''});
                setCID(0);
                setError(false);
            }
            else
                setError(true);
        }
    }

    return (
        <View style={styles.container}>
            <Text style={{fontFamily: 'STZhongsong', fontSize: 20}}>成语录入编辑</Text>
            <Text style={{fontFamily: 'Candara', fontSize: 30}}>{cID}</Text>
            <View style={styles.form}>
                <Formik
                    enableReinitialize
                    initialValues={values}
                    onSubmit={(values, actions) => { saveVocab(values) }}
                    validationSchema={schema}
                >
                    {({ handleChange, values }) => (
                <>
                    <FormLine 
                        name="chinese"
                        placeholder='Chinese'
                        autoCapitalize="none"
                        keyboardType="default"
                        textContentType="default"
                        value={values.chinese}
                        onChangeText={handleChange('chinese')}
                    />
                    <FormLine 
                        name="pinyin"
                        placeholder='Pinyin'
                        autoCapitalize="none"
                        keyboardType="default"
                        textContentType="default"
                        value={values.pinyin}
                        onChangeText={handleChange('pinyin')}
                    />
                    <FormLine 
                        name="english"
                        placeholder='English'
                        autoCapitalize="none"
                        keyboardType="default"
                        textContentType="default"
                        value={values.english}
                        onChangeText={handleChange('english')}
                    />
                    <FormLine 
                        name="example"
                        placeholder='Example'
                        autoCapitalize="none"
                        keyboardType="default"
                        textContentType="default"
                        value={values.example}
                        onChangeText={handleChange('example')}
                    />
                    <FormLine 
                        name="expEnglish"
                        placeholder='Example English'
                        autoCapitalize="none"
                        keyboardType="default"
                        textContentType="default"
                        value={values.expEnglish}
                        onChangeText={handleChange('expEnglish')}
                    />
                    <SubmitButton text="Update" color="dodgerblue"/>
                    </>
                    )}
                </Formik>
                {error && <ErrorMessage text="更新失败" />}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: '#EBF5FB',
        padding: 30
    },
    form: {
        width: '80%'
    },
    text: {
        fontFamily: 'Microsoft Black',
        fontSize: 25,
        marginRight: 30
    },
})